import React, { FC, ReactNode, useEffect, useRef, useState } from "react";

import {
  Column,
  ColumnDef,
  ColumnOrderState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Header,
  SortingState,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import downArrorwimg from "../../assets/downArrow.svg";
import { debounce } from "lodash";
import LoaderForTable from "../Loading/LoaderForTable";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { sortColumn } from "../../Redux/features/Orders/OrderListingSlice";
import { useDrag, useDrop } from "react-dnd";
import { fetchOrderedColumnData, updateColumnData, updateOrderedColumnData } from "../../Redux/features/Orders/OrderColumnSlice";
import Svg from "../Svg/Svg";

interface BasicTableProps<T> {
  columns: any;
  //   columns: ColumnDef<T>[];
  // pageSize?: number | any;
  hoverTooltip?: boolean;
  tableData: Array<T>;
  columnFilter: boolean;
  selectStatusArray: string[];
  nextFunction: any;
  height: number;
  isLoading?: boolean;
  isColumnDraggble?: boolean;
  DragAndDropIcon?: ReactNode;
  onRowClick?: (rowData: T) => void;
  filteredColumsStringArray?: string[];
}

interface DraggableColumnHeaderProp<T> {
  header: any;
  table: Table<T>;
  children: ReactNode;
  setHoveredColumnId: (columnId: string | null) => void;
}

const TableV8 = <T extends object>({
  columns,
  // pageSize = 40,
  hoverTooltip = false,
  tableData = [],
  nextFunction = () => {},
  height,
  isColumnDraggble = true,
  DragAndDropIcon,
  isLoading,
  onRowClick,
  filteredColumsStringArray = [],
}: BasicTableProps<T>) => {
  const [logged_user_id, set_logged_user_id] = React.useState(0);
  const [rowSelection, setRowSelection] = React.useState({});
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const [hoveredRowId, setHoveredRowId] = React.useState<string | null>(null);
  const filteredColumnStringArrays = useAppSelector((state) => state.orderColumnData.orderedColumns);
  const [columnOrder, setColumnOrder] = React.useState<ColumnOrderState>(filteredColumnStringArrays);
  const [hoveredColumnId, setHoveredColumnId] = React.useState<string | null>(null);
  // console.log(hoveredColumnId);
  const is_screen_lock = useAppSelector((state) => state.mainDashBoard.is_screen_lock);
  const loggedType = useAppSelector((state) => state.mainDashBoard.loggedType);
  // console.log(filteredColumnStringArrays);
  useEffect(() => {
    let userID = localStorage.getItem("login-id");
    set_logged_user_id(Number(userID));
    if (columnOrder.length === 0) {
      dispatch(fetchOrderedColumnData()).then((res: any) => {
        // console.log(res);
        // setColumnOrder(res.payload);
        setColumnOrder(filteredColumnStringArrays);
      });
    }
  }, []);

  // console.log(columns);
  // console.log(columnOrder, filteredColumsStringArray, "column order");

  useEffect(() => {
    if (columnOrder.length !== 0) {
      dispatch(updateOrderedColumnData(columnOrder))
        .then((result: any) => {
          // console.log(result);
          dispatch(fetchOrderedColumnData());
          setHoveredColumnId(null);
          // dispatch(fetchColumnData());
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, [columnOrder]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      rowSelection,
      sorting,
      globalFilter,
      // columnOrder: columnOrder,
      columnOrder: filteredColumsStringArray,
    },
    enableRowSelection: true,
    onColumnOrderChange: setColumnOrder, //column dnd state func
    // enableRowSelection: row => row.original.age > 18, // or enable row selection conditionally per row
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onGlobalFilterChange: setGlobalFilter,
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  React.useEffect(() => {
    table.setPageSize(tableData.length);
  }, [tableData]);
  // {
  //   table.getHeaderGroups().map((headerGroup: { id: React.Key | null | undefined; headers: any[] }) => {
  //     console.log(headerGroup.headers);
  //   });
  // }

  const scrollContainerRef = useRef(null);

  // Scroll event handler
  const handleScroll = () => {
    const currentRef: any = scrollContainerRef.current;

    // Check if currentRef is not null before proceeding
    if (currentRef) {
      const scrollTop = currentRef.scrollTop;
      const clientHeight = currentRef.clientHeight;
      const scrollHeight = currentRef.scrollHeight;

      if (scrollTop + clientHeight >= scrollHeight) {
        // console.log("Scroll reached bottom, calling API");
        currentRef.scrollTop = scrollTop + height / 2;
        if (isLoading === false) {
          nextFunction();
        }
      }
    }
  };

  const throttledHandleScroll = debounce(handleScroll, 100);

  useEffect(() => {
    const scrollContainer: any = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", throttledHandleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", throttledHandleScroll);
      }
    };
  }, [throttledHandleScroll]);

  const dispatch = useAppDispatch();
  const sort_dir = useAppSelector((state) => state.orders.sortDir);
  const sort_type = useAppSelector((state) => state.orders.sortType);
  // console.log(sort_dir, sort_type);

  //react-dnd code starts from here

  const reorderColumn = (draggedColumnId: string, targetColumnId: string, columnOrder: string[]): string[] => {
    // Create a copy of the columnOrder array to avoid mutating the original array
    const orderCopy = [...columnOrder];

    // Find the indexes of the dragged and target columns
    const draggedIndex = orderCopy.indexOf(draggedColumnId);
    const targetIndex = orderCopy.indexOf(targetColumnId);

    // Remove the dragged column from its original position
    const [removed] = orderCopy.splice(draggedIndex, 1);

    // Insert the dragged column before the target column
    orderCopy.splice(targetIndex, 0, removed);

    return orderCopy;
  };

  const DraggableColumnHeader = React.memo<DraggableColumnHeaderProp<any>>(({ header, table, children, setHoveredColumnId }) => {
    const { getState, setColumnOrder } = table;
    const { columnOrder } = getState();
    const { column } = header;
    const [{ isOver }, dropRef] = useDrop(
      () => ({
        accept: "column",
        drop: (draggedColumn: Column<T>) => {
          const newColumnOrder = reorderColumn(draggedColumn.id, column.id, columnOrder);
          // console.log("newColumnOrder", newColumnOrder);
          setColumnOrder(newColumnOrder);
        },
        collect: (monitor) => ({
          isOver: monitor.isOver(),
        }),
      }),
      [columnOrder, column.id]
    );

    useEffect(() => {
      if (isOver && hoveredColumnId !== header.id) {
        setHoveredColumnId(header.id);
      }
    }, [isOver, hoveredColumnId, header.id]);

    // const [, dropRef] = useDrop(()=>{
    //   return()
    //   {
    //     accept: "column",
    //     drop: (draggedColumn: Column<T>) => {
    //       const newColumnOrder = reorderColumn(draggedColumn.id, column.id, columnOrder);
    //       setColumnOrder(newColumnOrder);
    //     },
    //   }
    // }
    //   );

    // console.log("Hello", column?.id);

    const [{ isDragging }, dragRef, previewRef] = useDrag({
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      item: () => column,
      type: "column",
    });

    // console.log(isDragging);
    return (
      <>
        {/* <th ref={dropRef} colSpan={header.colSpan} style={{ opacity: isDragging ? 0.5 : 1 }}>
          <div ref={previewRef}>
            {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
            <button ref={dragRef}>🟰</button>
          </div>
        </th> */}
        <div
          key={header.id}
          ref={dropRef}
          // colSpan={header.colSpan}
          className={`text-left`}
          style={{
            opacity: isDragging ? 0.5 : 1,
          }}
        >
          {header.isPlaceholder ? null : (
            <>
              <div
                ref={previewRef}
                {...{
                  className: header.column.getCanSort()
                    ? `select-none p-1 font-[600] text-[12px] flex show-button`
                    : `p-1 font-[600] text-[12px] flex show-button`,
                }}
                style={{ cursor: header.column.columnDef.accessorKey !== "status" ? "pointer" : "" }}
              >
                <div
                  className="flex gap-2"
                  onClick={() => {
                    if (header.column.columnDef.accessorKey !== "status") {
                      dispatch(
                        sortColumn({
                          sortDir: sort_dir,
                          sortType: header.column.columnDef.sort_id,
                        })
                      );
                    }
                  }}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {sort_type === header.column.columnDef.sort_id && sort_dir === "asc" && (
                    <img src={downArrorwimg} alt="asc" className="w-[6px] rotate-180" />
                  )}
                  {sort_type === header.column.columnDef.sort_id && sort_dir === "desc" && <img src={downArrorwimg} alt="desc" className="w-[6px]" />}
                </div>
                {!is_screen_lock && (
                  <button ref={dragRef} className="cursor-grab h-auto w-[15px] flex justify-center flex-shrink-0 items-center ml-auto buttonManage">
                    {DragAndDropIcon}
                  </button>
                )}
              </div>
              {children}
              {/* <div className="p-1">
                {flexRender(header?.column?.columnDef?.Filter, header?.column?.columnDef)}
              </div> */}
            </>
          )}
        </div>
      </>
    );
  });

  // const DraggableColumnHeader: FC<{
  //   header: any;
  //   table: Table<T>;
  // }> = ({ header, table }) => {
  //   const { getState, setColumnOrder } = table;
  //   const { columnOrder } = getState();
  //   const { column } = header;

  //   const [, dropRef] = useDrop({
  //     accept: "column",
  //     drop: (draggedColumn: Column<T>) => {
  //       const newColumnOrder = reorderColumn(draggedColumn.id, column.id, columnOrder);
  //       setColumnOrder(newColumnOrder);
  //     },
  //   });

  //   console.log("Hello", column?.id);

  //   const [{ isDragging }, dragRef, previewRef] = useDrag({
  //     collect: (monitor) => ({
  //       isDragging: monitor.isDragging(),
  //     }),
  //     item: () => column,
  //     type: "column",
  //   });

  //   // console.log(isDragging);

  //   return (
  //     <>
  //       {/* <th ref={dropRef} colSpan={header.colSpan} style={{ opacity: isDragging ? 0.5 : 1 }}>
  //         <div ref={previewRef}>
  //           {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
  //           <button ref={dragRef}>🟰</button>
  //         </div>
  //       </th> */}
  //       <th
  //         key={header.id}
  //         ref={dropRef}
  //         colSpan={header.colSpan}
  //         className={`text-left`}
  //         style={{
  //           whiteSpace: "nowrap",
  //           // overflow: "hidden",
  //           width: header.column.columnDef.minSize,
  //           opacity: isDragging ? 0.5 : 1,
  //           borderRight: "0.5px solid #e3e3e3",
  //           // width: header.column.columnDef.maxSize,
  //         }}
  //       >
  //         {header.isPlaceholder ? null : (
  //           <>
  //             <div
  //               ref={previewRef}
  //               {...{
  //                 className: header.column.getCanSort() ? `select-none p-1 font-[600] text-[12px] flex` : `p-1 font-[600] text-[12px] flex`,
  //                 // onClick:
  //                 //   header.column.columnDef.accessorKey !== "status"
  //                 //     ? () => {
  //                 //         dispatch(
  //                 //           sortColumn({
  //                 //             sortDir: sort_dir,
  //                 //             sortType: header.column.columnDef.sort_id,
  //                 //           })
  //                 //         );
  //                 //       }
  //                 //     : () => {},
  //               }}
  //               style={{ cursor: header.column.columnDef.accessorKey !== "status" ? "pointer" : "" }}
  //             >
  //               {/* <div
  //                         {...{
  //                           className: header.column.getCanSort()
  //                             ? `select-none p-1 font-[600] text-[12px] flex gap-2`
  //                             : `p-1 font-[600] text-[12px] flex gap-2`,
  //                           onClick: header.column.columnDef.accessorKey !== "status" ? header.column.getToggleSortingHandler() : "",
  //                         }}
  //                         style={{ cursor: header.column.columnDef.accessorKey !== "status" ? "pointer" : "" }}
  //                       > */}
  //               <div
  //                 className="flex gap-2"
  //                 onClick={() => {
  //                   if (header.column.columnDef.accessorKey !== "status") {
  //                     dispatch(
  //                       sortColumn({
  //                         sortDir: sort_dir,
  //                         sortType: header.column.columnDef.sort_id,
  //                       })
  //                     );
  //                   }
  //                 }}
  //               >
  //                 {flexRender(header.column.columnDef.header, header.getContext())}
  //                 {sort_type === header.column.columnDef.sort_id && sort_dir === "asc" && (
  //                   <img src={downArrorwimg} alt="asc" className="w-[6px] rotate-180" />
  //                 )}
  //                 {sort_type === header.column.columnDef.sort_id && sort_dir === "desc" && <img src={downArrorwimg} alt="desc" className="w-[6px]" />}
  //               </div>

  //               <button ref={dragRef} className="cursor-grab h-auto w-[15px] flex justify-center flex-shrink-0 items-center ml-auto mr-[3px]">
  //                 {DragAndDropIcon}
  //               </button>
  //               {/* {{
  //                           asc: <img src={downArrorwimg} alt="asc" className="w-[6px] rotate-180" />,
  //                           desc: <img src={downArrorwimg} alt="desc" className="w-[6px]" />,
  //                         }[header.column.getIsSorted() as string] ?? null} */}
  //             </div>
  //             <div className="p-1">
  //               {/* column filter start */}
  //               {/* {columnFilter && header.column.getCanFilter() ? (
  //                           <div className={`min-w-[${header.column.columnDef.minSize}] w-[${header.column.columnDef.maxSize}]`}>
  //                             <Filter
  //                               column={header.column}
  //                               table={table}
  //                               placeHolder={header.id}
  //                               id={header.index}
  //                               selectStatusArray={selectStatusArray}
  //                             />
  //                           </div>
  //                         ) : null} */}
  //               {/* {header.column.columnDef?.meta?.filterComponent(header.column.setFilterValue)} */}
  //               {flexRender(header?.column?.columnDef?.Filter, header?.column?.columnDef)}
  //               {/* column filter end  */}
  //             </div>
  //           </>
  //         )}
  //       </th>
  //     </>
  //   );
  // };
  const [showTooltip, setShowTooltip] = useState("");

  return (
    <div id="OrdersBox" className="tbScroll" style={{ height: `${height}`, width: `100%`, overflowY: "auto" }} ref={scrollContainerRef}>
      {/* <table
        className="w-full sticky top-0 bg-layoutBg"
        style={{
          width: "100%",
          tableLayout: "fixed",
          height: tableData.length === 0 ? "100%" : "auto",
          // height: isLoading === false && tableData.length === 0 ? "100%" : isLoading === false && tableData.length !== 0 ? "" : "100%",
          overflowX: "hidden",
        }}
      > */}
      <table
        className={`w-full sticky top-0 bg-layoutBg`}
        style={{
          // width: "100%",
          // tableLayout: "fixed",
          height: tableData.length > 0 ? "" : "100%",
          overflowX: "hidden",
        }}
      >
        <thead className={`topspace sticky top-0 bg-layoutBg z-[50] ${!tableData.length || isLoading ? "h-[70px]" : ""}`}>
          {table.getHeaderGroups().map((headerGroup: { id: React.Key | null | undefined; headers: any[] }) => (
            <tr key={headerGroup.id} className="bg-white">
              {headerGroup.headers.map((header) => {
                // console.log(header);
                // console.log(header.column);
                // console.log(header.column.columnDef.FilterFn);
                // console.log(sort_type, header?.column?.columnDef?.sort_id, sort_dir, "asc");
                // console.log(sort_dir);
                return isColumnDraggble === true ? (
                  <th
                    key={header.id}
                    style={{
                      borderRight: isColumnDraggble ? "0.5px solid #e3e3e3" : "",
                      whiteSpace: "nowrap",
                      width: header.id === "Status" ? "118px" : "100%",
                      // width: header.column.columnDef.minSize,
                      borderRightColor: hoveredColumnId === header.id ? "#000000" : "#e3e3e3",
                    }}
                    colSpan={header.colSpan}
                  >
                    <DraggableColumnHeader key={header.id} header={header} table={table} children={[]} setHoveredColumnId={setHoveredColumnId} />
                    <div className="p-1">{flexRender(header?.column?.columnDef?.Filter, header?.column?.columnDef)}</div>
                  </th>
                ) : (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`text-left`}
                    style={{
                      whiteSpace: "nowrap",
                      width: header.id === "Status" ? "118px" : "100%",
                      // overflow: "hidden",
                      // width: header.column.columnDef.minSize,
                      // width: header.column.columnDef.maxSize,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? `select-none p-1 font-[600] text-[12px] flex gap-2`
                              : `p-1 font-[600] text-[12px] flex gap-2`,
                          }}
                          style={{ cursor: header.column.columnDef.accessorKey !== "status" ? "pointer" : "" }}
                        >
                          {/* <div
                          {...{
                            className: header.column.getCanSort()
                              ? `select-none p-1 font-[600] text-[12px] flex gap-2`
                              : `p-1 font-[600] text-[12px] flex gap-2`,
                            onClick: header.column.columnDef.accessorKey !== "status" ? header.column.getToggleSortingHandler() : "",
                          }}
                          style={{ cursor: header.column.columnDef.accessorKey !== "status" ? "pointer" : "" }}
                        > */}
                          <div
                            className="flex gap-2"
                            onClick={() => {
                              if (header.column.columnDef.accessorKey !== "status") {
                                dispatch(
                                  sortColumn({
                                    sortDir: sort_dir,
                                    sortType: header.column.columnDef.sort_id,
                                  })
                                );
                              }
                            }}
                          >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                            {sort_type === header.column.columnDef.sort_id && sort_dir === "asc" && (
                              <img src={downArrorwimg} alt="asc" className="w-[6px] rotate-180" />
                            )}
                            {sort_type === header.column.columnDef.sort_id && sort_dir === "desc" && (
                              <img src={downArrorwimg} alt="desc" className="w-[6px]" />
                            )}
                          </div>

                          {/* {{
                            asc: <img src={downArrorwimg} alt="asc" className="w-[6px] rotate-180" />,
                            desc: <img src={downArrorwimg} alt="desc" className="w-[6px]" />,
                          }[header.column.getIsSorted() as string] ?? null} */}
                        </div>
                        <div className="p-1">
                          {/* column filter start */}
                          {/* {columnFilter && header.column.getCanFilter() ? (
                            <div className={`min-w-[${header.column.columnDef.minSize}] w-[${header.column.columnDef.maxSize}]`}>
                              <Filter
                                column={header.column}
                                table={table}
                                placeHolder={header.id}
                                id={header.index}
                                selectStatusArray={selectStatusArray}
                              />
                            </div>
                          ) : null} */}
                          {/* {header.column.columnDef?.meta?.filterComponent(header.column.setFilterValue)} */}
                          {flexRender(header?.column?.columnDef?.Filter, header?.column?.columnDef)}
                          {/* column filter end  */}
                        </div>
                      </>
                    )}
                  </th>
                );

                // <DraggableColumnHeader key={header.id} header={header} table={table} />
              })}
              {headerGroup.headers.length === 0 && !isLoading && (
                <td className="text-center font-bold py-[25px] flex justify-center items-center">
                  Please do select atleast one column by clicking on above{" "}
                  <span className="w-[26px] mx-[5px] h-[26px] text-white bg-secondaryBg rounded-md flex justify-center items-center">
                    <Svg type="edit" />{" "}
                  </span>
                  icon.
                </td>
              )}
            </tr>
          ))}
        </thead>

        <tbody className={`overflow-y-auto w-full  ${!tableData.length || isLoading ? "heightTBodyNoData" : ""}`}>
          {table.getRowModel().rows.map((row: any, index: number) => {
            return (
              <React.Fragment key={row.id}>
                <tr
                  key={row.id}
                  onMouseOver={() => setShowTooltip(row.original.id)}
                  onMouseOut={() => setShowTooltip("")}
                  className={`relative ${index % 2 === 0 ? "text-left w-full bg-tableStripeBg" : "text-left w-full"}
                  ${
                    Number(row.original.is_validate_order) === 1 && row.original.status.toLowerCase() === "working" && loggedType === "internal"
                      ? "trade-invalid-bg"
                      : ""
                  }
                  hoverTableCell relative cursor-pointer overflow-auto`}
                  onClick={() => onRowClick && onRowClick(row.original)}
                  // onClick={() => {
                  //   dispatch(
                  //     posttransactionDetailsData({
                  //       record_type: row.original.record_type,
                  //       id: row.original.id,
                  //       is_status_ordered: 1,
                  //     })
                  //   );
                  //   dispatch(updatesetSideDrawer(true));
                  //   dispatch(updatesetSelectedID(row.original.id));
                  // }}
                >
                  {row.getVisibleCells().map((cell: any) => {
                    const isStatusCell = cell.column.columnDef.accessorKey === "status";

                    return (
                      <td
                        colSpan={1}
                        key={cell.id}
                        className={` text-[0.75rem]`}
                        style={{
                          width: isStatusCell ? "118px" : "",
                          display: isStatusCell ? "flex" : "",
                          justifyContent: isStatusCell ? "center" : "",
                          padding: "8px 6px",
                          whiteSpace: isStatusCell ? "normal" : "nowrap",
                          overflow: isStatusCell ? "initial" : "hidden",
                          textOverflow: isStatusCell ? "clip" : "ellipsis",
                          textAlign: cell.column.columnDef?.textAlign === "right" ? "right" : "left",
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                  {row.original.reason !== "" && String(row.original.reason) !== "N/A" && row.original.status !== "Filled" ? (
                    <td key={`cell-td-r-${index}`} className={showTooltip === row.original.id ? "show-tooltip" : "hide-tooltip"}>
                      {row.original.reason}
                    </td>
                  ) : null}
                </tr>
                {/* {row.original.status === "Rejected" ? ( */}

                {hoveredRowId === row.id && hoverTooltip === true && (
                  <tr className="border border-1">
                    <td colSpan={row.getVisibleCells().length} className="p-1">
                      Hello
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
          {isLoading && (
            <tr
              key={`loader-order-listing`}
              style={{ position: "relative", width: "100%" }}
              className={`${!tableData.length ? "min-h-[300px]" : ""}`}
            >
              <td
                className={`${
                  tableData.length === 0 ? "absolute pt-[50px] overflow-x-hidden" : "absolute pt-[10px] overflow-x-hidden"
                } w-[100%] overflow-x-hidden" colSpan={14`}
              >
                {/* <td className="relative w-[100%] overflow-x-hidden" colSpan={14}> */}
                <LoaderForTable customHieghtLoader={"50px"} />
              </td>
            </tr>
          )}
          {tableData.length === 0 && isLoading === false && (
            <tr
              key={`loader-order-listing `}
              style={{ position: "relative", width: "100%" }}
              className={`${!tableData.length ? "min-h-[300px]" : ""}`}
            >
              <td className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" colSpan={14}>
                <img src="./no-data-found.svg" alt="" />
                <p className="font-[12px] font-[500] text-center">Nothing To Display Here</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableV8;

// function Filter({
//   column,
//   table,
//   placeHolder,
// }: {
//   column: Column<any, any>;
//   table: Table<any>;
//   placeHolder: string;
// }) {
//   const firstValue = table
//     .getPreFilteredRowModel()
//     .flatRows[0]?.getValue(column.id);

//   return typeof firstValue === "number" ? (
//     <div className="flex space-x-2">
//       <input
//         type="number"
//         value={((column.getFilterValue() as any)?.[0] ?? "") as string}
//         onChange={(e) =>
//           column.setFilterValue((old: any) => [e.target.value, old?.[1]])
//         }
//         placeholder={`Min ${placeHolder}`}
//         className="w-24 border shadow rounded"
//       />
//       <input
//         type="number"
//         value={((column.getFilterValue() as any)?.[1] ?? "") as string}
//         onChange={(e) =>
//           column.setFilterValue((old: any) => [old?.[0], e.target.value])
//         }
//         placeholder={`Max ${placeHolder}`}
//         className="w-24 border shadow rounded"
//       />
//     </div>
//   ) : (
//     <input
//       type="text"
//       value={(column.getFilterValue() ?? "") as string}
//       onChange={(e) => column.setFilterValue(e.target.value)}
//       placeholder={`${placeHolder}`}
//       className="w-36 border shadow rounded"
//     />
//   );
// }
function Filter({
  id,
  column,
  table,
  placeHolder,
  selectStatusArray,
}: {
  id: number;
  column: Column<any, any>;
  table: Table<any>;
  placeHolder: string;
  selectStatusArray: string[];
}) {
  const [currentPlaceholder, setCurrentPlaceholder] = React.useState<number | null>(null);
  const handleFocus = () => {
    setCurrentPlaceholder(id); // Store the current id on focus
  };

  const handleBlur = (e: any) => {
    if (!e.target.value) {
      setCurrentPlaceholder(null); // Replace with your default placeholder
    }
  };
  const firstValue: any = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

  // Check if the value is in selectStatusArray or not
  if (selectStatusArray.includes(firstValue)) {
    return (
      <select
        value={(column.getFilterValue() as string) ?? ""}
        onChange={(e) => column.setFilterValue(e.target.value)}
        className="w-full border shadow outline-0 h-[35px] text-[#a4a3a3] text-[0.65rem]"
      >
        <option value="" className="text-[#a4a3a3] hover:bg-[#e5e5e5] focus:bg-[#e5e5e5e] accent-[#e5e5e5] text-[0.75rem]">
          All
        </option>
        {selectStatusArray &&
          selectStatusArray.map((item: string, index: number) => (
            <option value={item} className="text-[#a4a3a3] text-[0.75rem]" key={index}>
              {item}
            </option>
          ))}
      </select>
    );
  }
  // return (
  //   <div className="flex flex-col h-[35px] relative">
  //     <label
  //       htmlFor="filter"
  //       className={`absolute left-1 transition-all duration-300 ${
  //         isActive
  //           ? "top-0 text-xs text-[#a4a3a3]"
  //           : "top-1/2 left-1/4 -translate-x-1/4 -translate-y-1/2 text-[#a4a3a3]"
  //       }`}
  //     >
  //       {placeHolder}
  //     </label>
  //     <input
  //       type="text"
  //       id="filter"
  //       value={(column.getFilterValue() ?? "") as string}
  //       onChange={(e) => column.setFilterValue(e.target.value)}
  //       onFocus={handleFocus}
  //       onBlur={handleBlur}
  //       // placeholder={isActive ? "" : placeHolder}
  //       className="w-full border-[0.5px] shadow border-[#e5e5e5] focus:border-[#000000] outline-none px-1 pt-3 pb-1"
  //     />
  //   </div>
  // );

  return typeof firstValue === "number" ? (
    <div className="flex flex-col h-[35px] relative z-0">
      <label
        htmlFor="filter"
        className={`w-full h-full flex overflow-hidden absolute transition-all duration-300 ${
          currentPlaceholder === id
            ? "text-xs text-black px-[0.5rem] text-[0.65rem] justify-end items-start"
            : "text-[#a4a3a3] px-2 justify-end items-center text-[0.65rem]"
        }`}
        style={{ textOverflow: "ellipsis" }}
      >
        {placeHolder}
      </label>
      <input
        type="number"
        value={((column.getFilterValue() as any)?.[0] ?? "") as string}
        onChange={(e) => column.setFilterValue((old: any) => [e.target.value, old?.[1]])}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="relative top-0 left-0 right-0 bottom-0 w-full border-[0.5px] shadow border-[#e5e5e5] focus:border-[#000000] outline-none px-1 pt-3 pb-1 z-5 bg-transparent text-[12px] "
      />
    </div>
  ) : (
    <div className="flex flex-col h-[35px] relative">
      <label
        htmlFor="filter"
        className={`w-full h-full flex overflow-hidden absolute transition-all duration-300 ${
          currentPlaceholder === id
            ? "text-xs text-black px-[0.5rem] text-[0.65rem] justify-start items-start"
            : "text-[#a4a3a3] px-2 justify-start items-center text-[0.65rem]"
        }`}
      >
        {placeHolder}
      </label>
      <input
        type="text"
        id="filter"
        value={(column.getFilterValue() ?? "") as string}
        onChange={(e) => column.setFilterValue(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        // placeholder={isActive ? "" : placeHolder}
        className=" text-[12px] relative top-0 left-0 right-0 bottom-0 w-full border-[0.5px] shadow border-[#e5e5e5] focus:border-[#000000] outline-none px-1 pt-3 pb-1 z-5 bg-transparent"
      />
    </div>
  );
}

// function IndeterminateCheckbox({
//   indeterminate,
//   className = "",
//   ...rest
// }: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
//   const ref = React.useRef<HTMLInputElement>(null!);

//   React.useEffect(() => {
//     if (typeof indeterminate === "boolean") {
//       ref.current.indeterminate = !rest.checked && indeterminate;
//     }
//   }, [ref, indeterminate]);

//   return (
//     <input
//       type="checkbox"
//       ref={ref}
//       className={className + " cursor-pointer"}
//       {...rest}
//     />
//   );
// }
